import { useRouter, useSearchParams } from 'next/navigation';
import nProgress from 'nprogress';
import { useEffect } from 'react';

export function NProgressBar({ pathname }: { pathname: string }) {
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    // Save the original push method
    const originalPush = router.push.bind(router);

    // Override the router.push method
    router.push = async (href, options) => {
      // Start the progress bar
      nProgress.start();

      // Wait for the original push method to resolve
      await originalPush(href, options);

      // Check if the destination is the same as the current path
      const isSamePage = href === pathname || href.split('?')[0] === pathname;

      // If navigating to the same page, finish the progress immediately
      if (isSamePage) nProgress.done();
    };

    // Clean up on unmount: restore the original push method
    return () => {
      router.push = originalPush;
    };
  }, [router, pathname]);

  useEffect(() => {
    // Ensure progress is done on pathname or searchParams change
    nProgress.done();
  }, [pathname, searchParams]);

  return null;
}
