import WithoutBgHeader, { WithoutBgType } from './WithoutBgHeader';
import FullWidthImgHeader, { FullWidthImgType } from './FullWidthImgHeader';
import FullWidthHeader, { FullWidthType } from './FullWidthHeader';
import HorizontalSplitHeader, { HorizontalSplitType } from './HorizontalSplitHeader';
import SquareHeader, { SquareType } from './SquareHeader';
import HeroImageHeader, { HeroImageType } from './HeroImageHeader';

export type PageHeaderType = WithoutBgType | FullWidthImgType | FullWidthType | HorizontalSplitType | SquareType | HeroImageType;

const PageHeader = ({ pageHeader, dictionary }: { pageHeader: PageHeaderType; dictionary: Record<string, string> }) => {
  const { type } = pageHeader;

  switch (type) {
    case 'withoutBg':
      return <WithoutBgHeader pageHeader={pageHeader} dictionary={dictionary} />;
    case 'fullWidthImg':
      return <FullWidthImgHeader pageHeader={pageHeader} />;
    case 'fullWidth':
      return <FullWidthHeader pageHeader={pageHeader} dictionary={dictionary} />;
    case 'horizontalSplit':
      return <HorizontalSplitHeader pageHeader={pageHeader} dictionary={dictionary} />;
    case 'square':
      return <SquareHeader pageHeader={pageHeader} dictionary={dictionary} />;
    case 'heroImage':
      return <HeroImageHeader pageHeader={pageHeader} dictionary={dictionary} />;
    default:
      return null;
      break;
  }
};
export default PageHeader;
