import React, { useState } from 'react';
import { useParams } from 'next/navigation';
import Link from 'next/link';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { MdLanguage } from 'react-icons/md';
import capitalizeFirstLetter from 'shared/utils/capitalizeFirstLetter';
import { Locale } from '../../i18n/i18n-config';
import { getI18nUtils } from '../../i18n/i18n-utils';

const LanguageDropdown = ({
  availableLocales,
  dictionary,
}: {
  availableLocales: { locale: string; pathname: string }[];
  dictionary: Record<string, string>;
}): JSX.Element => {
  const params = useParams();
  const { locale }: { locale?: Locale } = params || {};
  const { formatMessage } = getI18nUtils(dictionary);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown
      toggle={toggleDropdown}
      onMouseLeave={() => setDropdownOpen(false)}
      isOpen={dropdownOpen}
      inNavbar
      className="languageDropdown order-lg-1"
    >
      <DropdownToggle nav className="languageDropdown-title d-flex align-items-center text-secondary">
        <span className="d-lg-none">{capitalizeFirstLetter(formatMessage({ id: 'pagenav-language' }))}</span>
        <div className="languageDropdown-globe d-none d-lg-block">
          <MdLanguage size={24} />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="languageMenu alwaysOpenMobile mb-5">
        {availableLocales.length > 1 ? (
          availableLocales
            .filter(({ locale: al }) => al !== locale)
            .map((l) => (
              <Link
                className="dropdown-item my-0 py-2 px-3 d-inline d-lg-inline-block"
                href={`/${l.locale}${l.pathname}`}
                locale={l.locale}
                key={`locale_switcher_${l.locale}`}
              >
                {capitalizeFirstLetter(formatMessage({ id: `${l.locale}` }))}
              </Link>
            ))
        ) : (
          <Link
            className="dropdown-item my-0 py-2 px-3"
            href={locale === 'nl' ? '/fr' : '/nl'}
            locale={locale === 'nl' ? 'fr' : 'nl'}
          >
            {locale === 'nl' ? 'FR' : 'NL'}
          </Link>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
