import Link from 'next/link';
import { Container, Row, Col } from 'reactstrap';
import CtaBtn from '../CtaBtn';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { Dictionaries } from '../../i18n/i18n-config';

export interface HeroImageType {
  title: keyof Dictionaries;
  intro: keyof Dictionaries;
  type: 'heroImage';
  ctaBtnLink: string;
  ctaBtnText: keyof Dictionaries;
  backgroundUrl: string;
  readMore?: boolean;
  darkened?: boolean;
  introLink?: JSX.Element;
}

const HeroImageHeader = ({
  pageHeader,
  dictionary,
}: {
  pageHeader: HeroImageType;
  dictionary: Record<string, string>;
}): JSX.Element => {
  const { backgroundUrl, title, intro, introLink, ctaBtnLink, ctaBtnText, readMore } = pageHeader;
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div id="heroImageHeader">
      <div className="heroContainer">
        <div
          id="heroImgContainer"
          className="p-0"
          style={{
            backgroundImage: `url(${backgroundUrl})`,
            backgroundPosition: 'center',
          }}
        />
        <Container className="heroTextContainer d-flex">
          <div className="textContainer d-flex flex-column justify-content-center m-2">
            <h1>{formatMessage({ id: title })}</h1>
            {introLink ? <p>{formatMessage({ id: intro }, { link: introLink })}</p> : <p>{formatMessage({ id: intro })}</p>}
            {ctaBtnLink && ctaBtnText && (
              <Link href={ctaBtnLink}>
                <CtaBtn className="btn-outline-white" type="button">
                  {formatMessage({ id: ctaBtnText })}
                </CtaBtn>
              </Link>
            )}
          </div>
          {readMore && (
            <div className="readMoreContainer">
              <Row className="text-center">
                <Col sm={12}>
                  <p className="readMoreText text-uppercase">{formatMessage({ id: 'myfirstjob-readmore' })}</p>
                  <svg height="10" viewBox="0 0 15 10" width="15" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <path d="m-2-5h20v20h-20z" />
                      <path
                        d="m13.2724864.32487243-6.06884936 6.22419753-6.06881729-6.22419753-1.13481975 1.16381893 7.20363704 7.38801646 7.20357286-7.38801646z"
                        fill="#FFF"
                        fillRule="nonzero"
                        transform="translate(.222222 .555556)"
                      />
                    </g>
                  </svg>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default HeroImageHeader;
