import { Container, Col, Row } from 'reactstrap';
import { Dictionaries } from '../../i18n/i18n-config';
import { getI18nUtils } from '../../i18n/i18n-utils';

export type HorizontalSplitType = {
  title: keyof Dictionaries;
  intro: keyof Dictionaries;
  backgroundUrl: string;
  type: 'horizontalSplit';
  introLink?: JSX.Element;
  darkened?: boolean;
};

const HorizontalSplitHeader = ({
  pageHeader,
  dictionary,
}: {
  pageHeader: HorizontalSplitType;
  dictionary: Partial<Dictionaries>;
}): JSX.Element => {
  const { title, intro, introLink, backgroundUrl } = pageHeader;
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div id="horizontalSplitHeader">
      <section className="my-5">
        <Container>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <div className="p-4">
                <h1>{formatMessage({ id: title })}</h1>
                {introLink ? <p>{formatMessage({ id: intro }, { link: introLink })}</p> : <p>{formatMessage({ id: intro })}</p>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="pageHeaderContainer horizontalSplit py-lg-5 py-4 mb-3 bg-light"
        style={{ backgroundImage: `url(${backgroundUrl})`, backgroundPosition: 'top' }}
      />
    </div>
  );
};
export default HorizontalSplitHeader;
