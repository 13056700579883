function capitalizeFirstLetter(_value: string): string;
function capitalizeFirstLetter(value: string | (string | JSX.Element)[]): string | (string | JSX.Element)[] {
  if (typeof value === 'string') return value.charAt(0).toUpperCase() + value.slice(1);
  if (Array.isArray(value) && typeof value[0] === 'string') {
    const [first, ...rest] = value;
    const capitalizedFirstWord = first.charAt(0).toUpperCase() + first.slice(1);
    return [capitalizedFirstWord, ...rest];
  }
  return value;
}

export default capitalizeFirstLetter;
