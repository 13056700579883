import Link from 'next/link';
import { Container } from 'reactstrap';
import CtaBtn from '../CtaBtn';
import { getI18nUtils } from '../../i18n/i18n-utils';

export interface WithoutBgType {
  type: 'withoutBg';
  title: string;
  intro: string;
  ctaBtnLink?: string;
  ctaBtnText?: string;
  backgroundUrl?: string;
  darkened?: boolean;
  noBgColor?: boolean;
}

const WithoutBgHeader = ({
  pageHeader,
  dictionary,
}: {
  pageHeader: WithoutBgType;
  dictionary: Record<string, string>;
}): JSX.Element => {
  const { title, intro, ctaBtnLink, ctaBtnText, noBgColor } = pageHeader;
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div id="withoutBgHeader">
      <Container fluid className="p-0">
        <div className={`${noBgColor ? 'noBgColor' : ''} pageHeaderContainer d-flex justify-content-center py-5 px-2`}>
          <div className="textContainer withoutBg d-flex flex-column justify-content-center m-2">
            <h1>{formatMessage({ id: title })}</h1>
            <hr />
            <p>{formatMessage({ id: intro })}</p>
            {ctaBtnLink && ctaBtnText && (
              <Link href={ctaBtnLink}>
                <CtaBtn className="cta red" type="button">
                  {formatMessage({ id: ctaBtnText })}
                </CtaBtn>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default WithoutBgHeader;
