'use client';

import { useState } from 'react';
import Link from 'next/link';
import { useRouter, useParams, usePathname } from 'next/navigation';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useUser } from 'shared/contexts/UserContext';
import capitalizeFirstLetter from 'shared/utils/capitalizeFirstLetter';
import Avatar from '../mySynergie/Avatar';
import MySynergieLinks from './MySynergieLinks';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { Dictionaries, Locale } from '../../i18n/i18n-config';

const MySynergieDropdown = ({ dictionary }: { dictionary: Partial<Dictionaries> }): JSX.Element => {
  const { candidate, user, isLoggedIn, signOut } = useUser();
  const { push } = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const { locale }: { locale?: Locale } = params || {};
  const { formatMessage } = getI18nUtils(dictionary);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const toggleContent = (
    <>
      <span className="d-lg-none">{capitalizeFirstLetter(formatMessage({ id: user ? 'pagenav-mysynergie' : 'login' }))}</span>

      <div className="d-none d-lg-flex align-items-center ms-auto">
        <Avatar dictionary={dictionary} />
        <div className="d-none d-lg-block text-secondary text-wrap ms-2">
          {isLoggedIn
            ? capitalizeFirstLetter(candidate?.firstName || formatMessage({ id: 'my-synergie' }))
            : capitalizeFirstLetter(formatMessage({ id: 'login' }))}
        </div>
      </div>
    </>
  );

  if (!user) {
    return (
      <Link className="text-secondary d-flex align-items-center ps-auto pe-0 nav-link order-lg-2" href={`/${locale}/auth/login/`}>
        {toggleContent}
      </Link>
    );
  }

  const handleSignOut = async () => {
    await signOut();
    if (pathname.includes('my-synergie') || pathname.includes('welcome')) push(`/auth/login/`);
  };

  return (
    <Dropdown
      toggle={toggleDropdown}
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
      isOpen={dropdownOpen}
      nav
      inNavbar
      className="mainDropdown order-lg-2"
    >
      <DropdownToggle nav className="text-secondary d-flex align-items-center ps-auto pe-0">
        {toggleContent}
      </DropdownToggle>
      <DropdownMenu end className="mainMenu alwaysOpenMobile">
        {isLoggedIn && (
          <>
            <MySynergieLinks dictionary={dictionary} />
            <hr className="d-none d-lg-block mx-3 my-2" />
          </>
        )}
        {/* logged in user without account should be able to finalize registration */}
        {user && !candidate?.id && (
          <Link href="/auth/register" className="py-2 px-3 my-0 dropdown-item">
            {capitalizeFirstLetter(formatMessage({ id: 'register' }))}
          </Link>
        )}
        {user && (
          <DropdownItem onClick={handleSignOut} className="py-2 px-3 my-0">
            {capitalizeFirstLetter(formatMessage({ id: 'pagenav-mysynergie-signout' }))}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MySynergieDropdown;
