'use client';

import { Container } from 'reactstrap';
import useWindowWidth from '../../hooks/useWindowWidth';

interface HeaderImage {
  name: string;
  description: string;
  small: string;
  medium: string;
  large: string;
}

export interface FullWidthImgType {
  type: 'fullWidthImg';
  backgroundUrl?: string;
  backgroundUrlMobile?: string;
  bgImageAlt?: string;
  srcSet?: HeaderImage;
  bigHeader?: boolean;
  darkened?: boolean;
}

const FullWidthImgHeader = ({ pageHeader }: { pageHeader: FullWidthImgType }): JSX.Element => {
  const { bigHeader, srcSet, bgImageAlt, backgroundUrl, backgroundUrlMobile } = pageHeader;
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth && windowWidth < 576;
  return (
    <div id="fullWidthImgHeader">
      <Container fluid className="p-0 mb-5">
        <div className={`pageHeaderContainer fullWidthImg d-flex justify-content-center${bigHeader ? 'bigHeader' : ''}`}>
          {(srcSet && (
            <img
              className="img-fluid"
              srcSet={`${srcSet.small} 600w, ${srcSet.medium} 1200w,${srcSet.large} 1800w`}
              alt={`banner ${bgImageAlt}`}
            />
          )) || (
            <img
              className="img-fluid"
              src={onMobile && backgroundUrlMobile ? backgroundUrlMobile : backgroundUrl}
              alt={`banner ${bgImageAlt}`}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
export default FullWidthImgHeader;
