import { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import { MdOutlinePhotoCamera, MdCheckCircle, MdWarning, MdPersonOutline } from 'react-icons/md';
import { Alert, Button, Badge } from 'reactstrap';
import { useUser } from '../../contexts/UserContext';
import { useMySynergieNotifications } from '../../contexts/MySynergieNotificationsContext';
import { getCandidate, saveCandidate } from '../../api/candidate';
import { getI18nUtils } from '../../i18n/i18n-utils';
import encodeBase64 from '../../utils/encodeBase64';
import { logSentryError } from '../../utils/logSentry';
import readFile from '../../utils/readFile';

function Avatar({
  header = false,
  edit = false,
  dictionary,
}: {
  header?: boolean;
  edit?: boolean;
  dictionary: Record<string, string>;
}): JSX.Element {
  const { formatMessage } = getI18nUtils(dictionary);
  const { setCandidate, candidate, isLoggedIn } = useUser();
  const [alert, setAlert] = useState<{ type: 'success' | 'warning'; message: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { mySynergieNotifications } = useMySynergieNotifications();
  const unreadNotifications =
    mySynergieNotifications && mySynergieNotifications.filter((notification) => !notification.readAt).length;

  const fileInputRef = useRef<HTMLInputElement>(null);

  async function handleFileInputChange(event) {
    setAlert(null);
    event.persist();
    const file = event.target.files[0];
    try {
      const base64 = await readFile(file);
      setLoading(true);
      await saveCandidate({ profilePicture: { file: encodeBase64(base64 as string), name: file.name } }, 'avatar');

      const newCandidate = await getCandidate();
      setCandidate((prev) => ({ ...prev, profilePicture: newCandidate.profilePicture }));
      setAlert({ type: 'success', message: formatMessage({ id: 'profilePicture-upload-succes' }) });
      setLoading(false);
    } catch {
      logSentryError(event);
      setAlert({ type: 'warning', message: formatMessage({ id: 'internal-server-error' }) });
      setLoading(false);
    }
  }

  const handleClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };
  useEffect(() => {
    if (!alert) return;
    setAlert(alert);
    const timer = setTimeout(() => setAlert(null), 3000);
    return () => clearTimeout(timer);
  }, [alert]);

  let content: string | JSX.Element = <MdPersonOutline size={24} />;

  if (isLoggedIn && candidate?.firstName && candidate?.lastName)
    content = candidate.firstName[0].toUpperCase() + candidate.lastName[0].toUpperCase();

  if (isLoggedIn && candidate?.profilePicture)
    content = <Image alt="" src={candidate.profilePicture} fill style={{ objectFit: 'cover' }} className="profilePicture" />;

  return (
    <div
      className={`navbar-avatar d-flex justify-content-center align-items-center ${header ? 'header' : ''} ${
        loading ? 'spinner' : ''
      } ${isLoggedIn ? '' : 'borderless'}`}
    >
      <div className={`navbar-avatar-inner ${header ? 'header' : ''}`}>
        {content}
        {unreadNotifications > 0 && !header && (
          <Badge pill color="primary" className="notifications-navbar-badge p-1">
            {unreadNotifications}
          </Badge>
        )}
        {edit && (
          <Button
            className={`camera-icon ${loading ? 'd-none' : 'd-flex'} align-items-center justify-content-center`}
            onClick={handleClick}
          >
            <MdOutlinePhotoCamera size={24} />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
              accept=".jpeg, .jpg, .png"
            />
          </Button>
        )}
      </div>
      {header && alert && (
        <Alert color={alert.type} isOpen={!!alert} className="profileAlert">
          {(() => {
            switch (alert.type) {
              case 'success':
                return <MdCheckCircle size={18} className="me-2" />;
              case 'warning':
                return <MdWarning size={18} className="me-2" />;
              default:
                break;
            }
          })()}
          {formatMessage({ id: alert.message[0] })}
        </Alert>
      )}
    </div>
  );
}

export default Avatar;
