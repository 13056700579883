function CareersLogo({
  textColor,
  mobile,
  width,
  height,
  className,
}: {
  textColor?: string;
  mobile?: boolean | number;
  width?: string;
  height?: string;
  className?: string;
}): JSX.Element {
  if (mobile) {
    return (
      <svg
        height={height || '60'}
        viewBox="0 0 115 60"
        xmlns="http://www.w3.org/2000/svg"
        className={`logo ${className ? ` ${className}` : ''}`}
        id="mobileLogo"
      >
        <g fill="none">
          <path d="m60 59.9789474v-59.9789474h-60v60z" fill="#e30613" />
          <g transform="translate(2 5)">
            <path d="m34.0226415 12.85625v-12.1125h-12.045283v12.1125z" fill="#fff" />
            <g fill="#fff" transform="translate(.602264 38.898125)">
              <path d="m4.76528302 4.7175-1.56377359-.68c-.85584905-.371875-1.03547169-.74375-1.03547169-1.370625 0-.595.24301886-1.16875 1.15169811-1.16875.77132075 0 1.09886792.5525 1.09886792 1.498125h1.94415095v-.286875c0-1.93375-1.31018868-2.709375-3.1064151-2.709375-1.91245283 0-3.10641509.99875-3.10641509 2.964375 0 1.67875.82415094 2.433125 2.63094339 3.049375 1.33132076.4675 1.79622642.8075 1.79622642 1.72125 0 .85-.59169811 1.243125-1.2045283 1.243125-1.06716981 0-1.33132076-.605625-1.33132076-1.55125v-.2975h-2.03924528v.414375c0 1.87.89811321 2.943125 3.1909434 2.943125 2.18716981 0 3.48679245-.95625 3.48679245-3.091875 0-1.2325-.40150943-2.01875-1.91245283-2.6775z" />
              <path d="m10.650566 4.090625h-.021132l-1.32075475-3.899375h-2.28226416l2.59924529 6.12v3.97375h2.02867922v-3.97375l2.5992453-6.12h-2.1977358z" />
              <path d="m19.8007547 7.108125h-.0211321l-2.4513207-6.916875h-2.3350944v10.09375h1.8596227v-7.065625h.0211321l2.4830188 7.065625h2.3033963v-10.09375h-1.8596227z" />
              <path d="m25.0837736 5.9075h3.3811321v-1.668125h-3.3811321v-2.38h3.5924528v-1.668125h-5.610566v10.09375h5.7479245v-1.668125h-3.7298113z" />
              <path d="m32.6913208 4.600625h-.8452831v-2.91125h.9509434c.9086793 0 1.4052831.4675 1.4052831 1.4025.010566 1.08375-.538868 1.50875-1.5109434 1.50875zm3.4867924 3.793125c-.0316981-2.135625-.2430189-2.900625-1.6166038-3.08125v-.031875c1.1411321-.244375 1.669434-1.1475 1.669434-2.48625 0-1.72125-1.0143396-2.603125-2.6732076-2.603125h-3.7298113v10.09375h2.0286793v-4.27125h1.0777358c.8135849 0 1.1622642.478125 1.1939623 1.275l.0528302 1.93375c.010566.393125.0528302.775625.2535849 1.0625h2.1871698v-.085c-.3698113-.201875-.4332076-.74375-.4437736-1.80625z" />
              <path d="m40.869434 6.343125h1.3313207v1.030625c0 1.08375-.7713207 1.615-1.2467924 1.615-1.1622642 0-1.394717-1.53-1.394717-3.9525 0-2.518125.3909434-3.538125 1.3735849-3.538125.8135849 0 1.1516981.945625 1.1516981 1.7h1.9441509c0-2.050625-.8981132-3.198125-3.1169811-3.198125-2.5886792 0-3.370566 1.7425-3.370566 5.131875 0 3.495625.4966037 5.355 2.9796226 5.355.9403774 0 1.6271698-.34 2.1132076-1.12625h.0316981v.935h1.4898113v-5.44h-3.2754717v1.4875z" />
              <path d="m45.5501887 10.285h2.0286792v-10.09375h-2.0286792z" />
              <path d="m51.1079245 8.616875v-2.709375h3.3705661v-1.668125h-3.3705661v-2.38h3.5818868v-1.668125h-5.610566v10.09375h5.7479245v-1.668125z" />
            </g>
            <g fill="#303337" transform="translate(66 20)">
              <path d="m6.08897275 6.33515625c-.16201258 1.95234375-1.24209644 3.240625-2.97023061 3.240625-1.97115304 0-3.11874214-1.34140625-3.11874214-4.79453125 0-3.17421875 1.16109015-4.78125 3.21324948-4.78125 1.75513626 0 2.76771488 1.04921875 2.87572327 2.93515625h-1.05308176c-.12150944-1.434375-.70205451-2.071875-1.82264151-2.071875-1.1475891 0-2.09266248.690625-2.09266248 3.93125 0 3.453125.99907757 3.91796875 1.98465409 3.91796875 1.06658281 0 1.75513627-.7703125 1.9306499-2.37734375z" />
              <path d="m7.43907757 2.93515625c0-1.8859375.91807128-2.93515625 2.87572323-2.93515625 1.9306499 0 2.6327044.9828125 2.6327044 2.43046875v5.41875c0 .51796875.1755137.75703125.540042.75703125h.297023v.75703125c-.1890146.06640625-.3510272.10625-.4860377.10625-.8235639 0-1.3501048-.1859375-1.3501048-1.1421875v-.30546875h-.0405032c-.4185325 1.20859375-1.3636058 1.55390625-2.40318655 1.55390625-1.63362684 0-2.36268344-.99609375-2.36268344-2.58984375 0-1.20859375.56704402-2.178125 1.8766457-2.53671875l1.98465409-.5578125c.7425576-.2125.8910692-.38515625.8910692-1.44765625 0-1.16875-.6615514-1.59375-1.6201258-1.59375-1.24209644 0-1.79563942.74375-1.79563942 2.071875h-1.03958071zm4.45534593 1.26171875h-.0405032c-.1080084.30546875-.7020545.5046875-1.120587.6109375l-.8775681.22578125c-1.05308176.27890625-1.59312369.81015625-1.59312369 1.89921875 0 1.07578125.56704402 1.79296875 1.47161426 1.79296875 1.28259953 0 2.16016773-.94296875 2.16016773-2.58984375z" />
              <path d="m15.107673.2125h1.0530817v1.434375h.0405032c.4050314-1.02265625 1.2690985-1.646875 2.3491824-1.646875.1215094 0 .2430188.01328125.3645283.053125v1.0359375c-.1755137-.03984375-.3510273-.053125-.5130399-.053125-1.1610901 0-2.241174.8234375-2.241174 2.603125v5.7375h-1.0530817z" />
              <path d="m25.7329979 6.33515625c-.1620126 1.95234375-1.2420964 3.240625-2.9972327 3.240625-1.971153 0-3.1187421-1.34140625-3.1187421-4.79453125 0-3.17421875 1.1610901-4.78125 3.2132494-4.78125 2.0656604 0 2.9972327 1.39453125 2.9972327 4.44921875v.38515625h-5.0898951v.43828125c0 2.64296875.9450733 3.453125 1.9846541 3.453125 1.1070859 0 1.7956394-.7703125 1.971153-2.37734375h1.0395807zm-1.0395807-2.3640625c-.0405032-2.284375-.6885535-3.1078125-1.9846541-3.1078125s-1.9441509.8234375-1.9846541 3.1078125z" />
              <path d="m33.2125786 6.33515625c-.1620126 1.95234375-1.2420964 3.240625-2.9972327 3.240625-1.971153 0-3.1187421-1.34140625-3.1187421-4.79453125 0-3.17421875 1.1610901-4.78125 3.2132494-4.78125 2.0656604 0 2.9972328 1.39453125 2.9972328 4.44921875v.38515625h-5.0898952v.43828125c0 2.64296875.9450734 3.453125 1.9846541 3.453125 1.1070859 0 1.7956394-.7703125 1.971153-2.37734375h1.0395807zm-1.0395807-2.3640625c-.0405031-2.284375-.6885535-3.1078125-1.9846541-3.1078125s-1.9441509.8234375-1.9846541 3.1078125z" />
              <path d="m34.9407128.2125h1.0530817v1.434375h.0405032c.4050314-1.02265625 1.2690985-1.646875 2.3491824-1.646875.1215094 0 .2430189.01328125.3645283.053125v1.0359375c-.1755136-.03984375-.3510273-.053125-.5130398-.053125-1.1610902 0-2.2411741.8234375-2.2411741 2.603125v5.7375h-1.0530817z" />
              <path d="m41.2997065 4.82109375c-1.3096017-.5046875-1.7956394-1.26171875-1.7956394-2.178125 0-1.8859375 1.1880922-2.64296875 2.7677149-2.64296875 1.7551362 0 2.5651991.8765625 2.5651991 2.55v.25234375h-1.0530817v-.2390625c0-1.1953125-.5670441-1.68671875-1.5256185-1.68671875-1.2285954 0-1.7011321.6375-1.7011321 1.55390625 0 .6375.2160168 1.1421875 1.2690986 1.540625l1.5256184.57109375c1.2690986.46484375 1.7416352 1.328125 1.7416352 2.32421875 0 1.5671875-.9180712 2.72265625-2.8757232 2.72265625-1.9171489 0-2.8622222-.7703125-2.8622222-2.85546875v-.2921875h1.0530817v.2390625c0 1.34140625.5940461 2.03203125 1.7956394 2.03203125 1.120587 0 1.8496436-.62421875 1.8496436-1.646875 0-.81015625-.3375262-1.328125-1.2690985-1.68671875z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg
      enableBackground="new 0 0 56.69 70.87"
      viewBox="0 0 56.69 70.87"
      xmlns="http://www.w3.org/2000/svg"
      height={height || '60'}
      width={width || '60'}
      className={`logo${className ? ` ${className}` : ''}`}
    >
      <path d="m57 56.98v-56.98h-57v57z" fill="#e30613" />
      <g fill="#fff">
        <path d="m34.2 17.1v-11.4h-11.4v11.4z" />
        <path d="m7.08 46.05-1.48-.64c-.81-.35-.98-.7-.98-1.29 0-.56.23-1.1 1.09-1.1.73 0 1.04.52 1.04 1.41h1.84v-.27c0-1.82-1.24-2.55-2.94-2.55-1.81 0-2.94.94-2.94 2.79 0 1.58.78 2.29 2.49 2.87 1.26.44 1.7.76 1.7 1.62 0 .8-.56 1.17-1.14 1.17-1.01 0-1.26-.57-1.26-1.46v-.28h-1.93v.39c0 1.76.85 2.77 3.02 2.77 2.07 0 3.3-.9 3.3-2.91 0-1.16-.38-1.9-1.81-2.52z" />
        <path d="m12.65 45.46h-.02l-1.25-3.67h-2.16l2.46 5.76v3.74h1.92v-3.74l2.46-5.76h-2.08z" />
        <path d="m21.31 48.3h-.02l-2.32-6.51h-2.21v9.5h1.76v-6.65h.02l2.35 6.65h2.18v-9.5h-1.76z" />
        <path d="m26.31 47.17h3.2v-1.57h-3.2v-2.24h3.4v-1.57h-5.31v9.5h5.44v-1.57h-3.53z" />
        <path d="m33.51 45.94h-.8v-2.74h.9c.86 0 1.33.44 1.33 1.32.01 1.02-.51 1.42-1.43 1.42zm3.3 3.57c-.03-2.01-.23-2.73-1.53-2.9v-.03c1.08-.23 1.58-1.08 1.58-2.34 0-1.62-.96-2.45-2.53-2.45h-3.53v9.5h1.92v-4.02h1.02c.77 0 1.1.45 1.13 1.2l.05 1.82c.01.37.05.73.24 1h2.07v-.08c-.35-.19-.41-.7-.42-1.7z" />
        <path d="m41.25 47.58h1.26v.97c0 1.02-.73 1.52-1.18 1.52-1.1 0-1.32-1.44-1.32-3.72 0-2.37.37-3.33 1.3-3.33.77 0 1.09.89 1.09 1.6h1.84c0-1.93-.85-3.01-2.95-3.01-2.45 0-3.19 1.64-3.19 4.83 0 3.29.47 5.04 2.82 5.04.89 0 1.54-.32 2-1.06h.03v.88h1.41v-5.12h-3.1v1.4z" />
        <path d="m45.68 51.29h1.92v-9.5h-1.92z" />
        <path d="m50.94 49.72v-2.55h3.19v-1.57h-3.19v-2.24h3.39v-1.57h-5.31v9.5h5.44v-1.57z" />
      </g>
      <g fill={textColor || '#000'}>
        <path d="m25.9 65.74c-.12 1.47-.92 2.44-2.2 2.44-1.46 0-2.31-1.01-2.31-3.61 0-2.39.86-3.6 2.38-3.6 1.3 0 2.05.79 2.13 2.21h-.78c-.09-1.08-.52-1.56-1.35-1.56-.85 0-1.55.52-1.55 2.96 0 2.6.74 2.95 1.47 2.95.79 0 1.3-.58 1.43-1.79z" />
        <path d="m26.9 63.18c0-1.42.68-2.21 2.13-2.21 1.43 0 1.95.74 1.95 1.83v4.08c0 .39.13.57.4.57h.22v.57c-.14.05-.26.08-.36.08-.61 0-1-.14-1-.86v-.23h-.03c-.31.91-1.01 1.17-1.78 1.17-1.21 0-1.75-.75-1.75-1.95 0-.91.42-1.64 1.39-1.91l1.47-.42c.55-.16.66-.29.66-1.09 0-.88-.49-1.2-1.2-1.2-.92 0-1.33.56-1.33 1.56h-.77zm3.3.95h-.03c-.08.23-.52.38-.83.46l-.65.17c-.78.21-1.18.61-1.18 1.43 0 .81.42 1.35 1.09 1.35.95 0 1.6-.71 1.6-1.95z" />
        <path d="m32.58 61.13h.78v1.08h.03c.3-.77.94-1.24 1.74-1.24.09 0 .18.01.27.04v.78c-.13-.03-.26-.04-.38-.04-.86 0-1.66.62-1.66 1.96v4.32h-.78z" />
        <path d="m40.45 65.74c-.12 1.47-.92 2.44-2.22 2.44-1.46 0-2.31-1.01-2.31-3.61 0-2.39.86-3.6 2.38-3.6 1.53 0 2.22 1.05 2.22 3.35v.29h-3.77v.33c0 1.99.7 2.6 1.47 2.6.82 0 1.33-.58 1.46-1.79h.77zm-.77-1.78c-.03-1.72-.51-2.34-1.47-2.34s-1.44.62-1.47 2.34z" />
        <path d="m45.99 65.74c-.12 1.47-.92 2.44-2.22 2.44-1.46 0-2.31-1.01-2.31-3.61 0-2.39.86-3.6 2.38-3.6 1.53 0 2.22 1.05 2.22 3.35v.29h-3.77v.33c0 1.99.7 2.6 1.47 2.6.82 0 1.33-.58 1.46-1.79h.77zm-.77-1.78c-.03-1.72-.51-2.34-1.47-2.34s-1.44.62-1.47 2.34z" />
        <path d="m47.27 61.13h.78v1.08h.03c.3-.77.94-1.24 1.74-1.24.09 0 .18.01.27.04v.78c-.13-.03-.26-.04-.38-.04-.86 0-1.66.62-1.66 1.96v4.32h-.78z" />
        <path d="m51.98 64.6c-.97-.38-1.33-.95-1.33-1.64 0-1.42.88-1.99 2.05-1.99 1.3 0 1.9.66 1.9 1.92v.19h-.78v-.18c0-.9-.42-1.27-1.13-1.27-.91 0-1.26.48-1.26 1.17 0 .48.16.86.94 1.16l1.13.43c.94.35 1.29 1 1.29 1.75 0 1.18-.68 2.05-2.13 2.05-1.42 0-2.12-.58-2.12-2.15v-.22h.78v.18c0 1.01.44 1.53 1.33 1.53.83 0 1.37-.47 1.37-1.24 0-.61-.25-1-.94-1.27z" />
      </g>
    </svg>
  );
}

export default CareersLogo;
