'use client';

import { Container } from 'reactstrap';
import Link from 'next/link';
import CtaBtn from '../CtaBtn';
import useWindowWidth from '../../hooks/useWindowWidth';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { Dictionaries } from '../../i18n/i18n-config';

export interface FullWidthType {
  type: 'fullWidth';
  title?: keyof Dictionaries;
  intro?: keyof Dictionaries;
  darkened?: boolean;
  divider?: boolean;
  backgroundUrl?: string;
  backgroundUrlMobile?: string;
  introLink?: JSX.Element;
  textPosition?: string;
  ctaBtnLink?: string;
  ctaBtnText?: keyof Dictionaries;
}

const FullWidthHeader = ({
  pageHeader,
  dictionary,
}: {
  pageHeader: FullWidthType;
  dictionary: Record<string, string>;
}): JSX.Element => {
  const { darkened, title, intro, textPosition, introLink, ctaBtnLink, ctaBtnText, backgroundUrl, backgroundUrlMobile, divider } =
    pageHeader;
  const { formatMessage } = getI18nUtils(dictionary);
  const width = useWindowWidth();

  return (
    <div id="fullWidthHeader">
      <Container fluid className="p-0 mb-5">
        <div
          className={`pageHeaderContainer fullWidth ${darkened ? 'darkened' : ''} d-flex justify-content-center py-5 px-2`}
          style={{
            backgroundImage: `url(${width && width < 576 && backgroundUrlMobile ? backgroundUrlMobile : backgroundUrl})`,
            backgroundPosition: 'center',
          }}
        >
          {title && intro && (
            <div
              className={`textWrapper d-flex m-2 ${
                textPosition && textPosition === 'left' ? 'justify-content-start' : 'justify-content-center'
              }
            `}
            >
              <div className="textContainer d-flex flex-column justify-content-center m-2">
                <h1>{formatMessage({ id: title })}</h1>
                {divider && <hr />}
                {introLink ? <p>{formatMessage({ id: intro }, { link: introLink })}</p> : <p>{formatMessage({ id: intro })}</p>}
                {ctaBtnLink && ctaBtnText && (
                  <Link href={ctaBtnLink}>
                    <CtaBtn className="cta red" type="button">
                      {formatMessage({ id: ctaBtnText })}
                    </CtaBtn>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
export default FullWidthHeader;
