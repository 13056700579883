const readFile = (file: File): Promise<FileReader['result']> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event?.target === null) return;
      resolve(event.target.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export default readFile;
