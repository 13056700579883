/* eslint-disable react/destructuring-assignment */
import React from 'react';

const CtaBtn = (props) => (
  // eslint-disable-next-line react/button-has-type
  <button {...props}>
    {props.children}
    <svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="evenodd">
        <path
          className="fillColor"
          d="m1.1117.02285-.8838.883775 4.7267 4.726525-4.7267 4.7265.8838.883775 5.610525-5.610275z"
          fillRule="nonzero"
          transform="translate(.5 .25)"
        />
      </g>
    </svg>
  </button>
);

export default CtaBtn;
