import Link from 'next/link';
import { Container, Row, Col } from 'reactstrap';
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import CtaBtn from '../CtaBtn';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { Dictionaries } from '../../i18n/i18n-config';

export interface SquareType {
  title: keyof Dictionaries;
  intro: keyof Dictionaries;
  backgroundUrl: string;
  type: 'square';
  shareUrl?: string;
  shareTitle?: string;
  shareDescription?: string;
  ctaBtnLink?: string;
  ctaBtnText?: keyof Dictionaries;
  bgImageAlt?: string;
  linkToSection?: () => void;
  darkened?: boolean;
}

const SquareHeader = ({
  pageHeader,
  dictionary,
}: {
  pageHeader: SquareType;
  dictionary: Record<string, string>;
}): JSX.Element => {
  const {
    title,
    intro,
    ctaBtnLink,
    backgroundUrl,
    ctaBtnText,
    linkToSection,
    shareUrl,
    shareTitle,
    shareDescription,
    bgImageAlt,
  } = pageHeader;
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div id="squareHeader">
      <Container id="headerContainer" className="mt-4 mt-lg-0">
        <Row className="position-relative">
          <Col id="header-text" className="mb-lg-4 mt-lg-5" lg={{ size: 5 }}>
            <div className="my-3">
              <h1 className="mb-3 mt-lg-5">
                <span>{formatMessage({ id: title })}</span>
              </h1>
              <p className="mb-3">{formatMessage({ id: intro })}</p>
              {ctaBtnLink && ctaBtnText && (
                <Link href={ctaBtnLink}>
                  <CtaBtn className="cta red" type="button">
                    {formatMessage({ id: ctaBtnText })}
                  </CtaBtn>
                </Link>
              )}
              {linkToSection && ctaBtnText && (
                <a>
                  <CtaBtn className="cta red" type="button" onClick={linkToSection}>
                    {formatMessage({ id: ctaBtnText })}
                  </CtaBtn>
                </a>
              )}
              {shareUrl && (
                <>
                  <EmailShareButton url={shareUrl} subject={shareTitle ? formatMessage({ id: shareTitle }) : undefined}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <LinkedinShareButton
                    className="ms-2"
                    title={shareTitle ? formatMessage({ id: shareTitle }) : undefined}
                    summary={shareDescription ? formatMessage({ id: shareDescription }) : undefined}
                    url={shareUrl}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={shareTitle ? formatMessage({ id: shareTitle }) : undefined}
                    className="ms-2"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <FacebookShareButton url={shareUrl} className="ms-2">
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </>
              )}
            </div>
          </Col>
          <Col lg={{ offset: 3, size: 9 }} className="d-lg-flex justify-content-lg-end" id="headerImgContainer">
            <img id="header-img" className="img-fluid" src={backgroundUrl} alt={bgImageAlt || 'header'} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SquareHeader;
