import Link from 'next/link';
import { Badge } from 'reactstrap';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { useMySynergieNotifications } from '../../contexts/MySynergieNotificationsContext';
import { Dictionaries, Locale } from '../../i18n/i18n-config';
import { useParams } from 'next/navigation';
import { getI18nUtils } from '../../i18n/i18n-utils';

type Props = {
  dictionary: Partial<Dictionaries>;
};
function MySynergieLinks({ dictionary }: Props) {
  const { formatMessage } = getI18nUtils(dictionary);
  const { mySynergieNotifications: notifications } = useMySynergieNotifications();
  const { locale }: { locale: Locale } = useParams();

  const unreadNotifications = notifications && notifications.filter((notification) => !notification.readAt).length;
  return (
    <>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'pagenav-mysynergie-dashboard' }))}
      </Link>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/profile`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'pagenav-mysynergie-profile' }))}
      </Link>
      <hr className="d-none d-lg-block mx-3 my-2" />
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/saved-searches`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'mysynergie_searches' }))}
      </Link>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/notifications`} locale={locale}>
        {' '}
        <div className="d-flex justify-content-between align-items-center">
          {capitalizeFirstLetter(formatMessage({ id: 'mysynergie_notifications' }))}
          {unreadNotifications > 0 && (
            <Badge pill color="primary" className="notifications-badge">
              {unreadNotifications}
            </Badge>
          )}
        </div>
      </Link>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/job-applications`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'mysynergie_applications' }))}
      </Link>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/favorite-jobs`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'mysynergie_favorites' }))}
      </Link>
      <Link className="dropdown-item my-0 py-2 px-3" href={`/${locale}/my-synergie/jobmatches`} locale={locale}>
        {capitalizeFirstLetter(formatMessage({ id: 'mysynergie_jobmatches' }))}
      </Link>
    </>
  );
}

export default MySynergieLinks;
